@import "../../styles/hairline.less";

.home{
    width: 100%;
    padding-top: 7vh;
    background-color: @htmlBackgrount;
    .adm-pull-to-refresh{
        width: 100%;
        height: 100%;
    }
    .banner{
        width: 100%;
        // height: 400px;
        background-color: #ffffff;
        padding-top: 30px;
        padding-bottom: 30px;
        margin-bottom: 20px;
        .swiper{
            width: 100%;
            // height: 351px;
            .swiper_item{
                width: 92%;
                // width: 702px;
                // height: 351px;
                border-radius: 10px;
                overflow: hidden;
                margin: 0 auto;
                img{
                    width: 100%;
                    // width: 702px;
                    // height: 351px;
                    margin: 0 auto;
                }
            }
            .swiper-pagination-bullet-active{
                background-color: #2b7ab0;
            }
        }
    }
    .main_content{
        width: 100%;
        height: auto;
        &>.item{
            width: 100%;
            height: auto;
            box-sizing: border-box;
            background-color: #ffffff;
            margin-bottom: 20px;
            cursor: pointer;
            &:nth-last-of-type(1){
                border-bottom: 50px solid #F5F5F5;
            }
        }
    }
    .create{
        font-size:0;
        line-height:0;
        border-radius:50%;
        z-index: 10;
        box-shadow: 0px 32px 32px 0px rgba(43, 122, 176, 0.2);
        img{
            width: 100%;
            height: 100%;
        }
    }
}
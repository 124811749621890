@import "../../styles/hairline.less";
.createTopic{
    width: 100%;
    box-sizing: border-box;
    min-height:100vh;
    padding-top: 100px;
    background-color: #f5f5f5;
    .header{
        width: 100%;
        height: auto;
        padding: 32px 35px 0;
        box-sizing: border-box;
        margin-bottom: 32px;
        .author{
            width: 100%;
            height: 80px;
            display: flex;
            align-items: center;
            .img{
                width: 80px;
                height: 80px;
                border-radius: 50%;
                position: relative;
                .headimg{
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                }
                .vip{
                    width: 29px;
                    height: 29px;
                    position: absolute;
                    right: -5px;
                    bottom: -5px;
                }
            }
            .right{
                margin-left: 20px;
                .name{
                    height: 30px;
                    font-size: 30px;
                    color: #000000;
                    display: flex;
                    align-items: center;
                    p{
                        display: inline-block;
                        background-color: #C40B44;
                        border-radius: 6px;
                        color: #ffffff;
                        text-align: center;
                        padding:10px;
                        line-height: 0;
                        margin-left: 11px;
                        font-size: 20px;
                        line-height: 20px;
                    }
                }
                .time{
                    margin-top: 12px;
                    font-size: 24px;
                    color: #7f7f7f;
                }
            }
        }
        .title{
            border-top: 1px solid #E5E5E5;
            padding-top: 38px;
            margin-top: 29px;
            width: 100%;
            font-size: 40px;
            font-weight: bold;
            color: #000000;
            line-height: 58px;
        }
        .desc{
            padding: 40px 0;
            font-size: 32px;
            font-family: SourceHanSansCN;
            font-weight: 400;
            color: #333333;
            line-height: 52px;
        }
    }
    .select{
        width: 96%;
        height: 94px;
        margin: 0 35px;
        box-sizing: border-box;
        margin: 0 auto;
        background: #FFFFFF;
        border-radius: 10px;
        display: flex;
        align-items: center;
        font-size: 30px;
        margin-bottom: 22px;
    }
    .textarea{
        width: 96%;
        margin: 0 auto;
        background-color: #ffffff;
        padding: 30px;
        box-sizing: border-box;
        margin-bottom: 22px;
        border-radius: 10px;
    }
    .setVoteList{
        width: 96%;
        margin: 0 auto;
        background-color: #ffffff;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 10px;
        margin-bottom: 22px;
        & > p{
            font-size: 30px;
            color: #000000;
        }
        .adm-list-card{
            margin-top: 0;
            margin-bottom: 0;
        }
        .adm-list-item{
            margin-left: 0;
            padding-left: 0;
        }
        .adm-input-element{
            font-size: 30px;
            flex: none;
            width: 80%;
            background-color: #F6F6F6;
            box-sizing: border-box;
            padding: 20px 20px;
            border-radius: 6px;
        }
        .adm-form-item-label,.label{
            color: #888888;
            font-size: 30px;
            line-height: 3;
        }
        .time{
            width: 100%;
            display: flex;
            align-items: center;
            .label{
                margin-right: 20px;
            }
            p{
                cursor: pointer;
                width: 70%;
                color: #888888;
                font-size: 30px;
                background-color: #F6F6F6;
                box-sizing: border-box;
                padding: 20px 20px;
                border-radius: 6px;
                margin: 0;
                i{
                    margin-right: 10px;
                }
            }
        }
        .adm-list-item-content-prefix{
            width: 150px;
        }
        .adm-list:nth-last-of-type(1){
            width:100px;
            margin: 0 auto;
            .adm-list-item-content{
                padding: 0;
            }
        }
        .formItemBox{
            position: relative;
        }
        .remove_add{
            width: 16%;
            height: 130px;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 999;
            font-size: 40px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            i{
                cursor: pointer;
            }
        }
    }
    .createTopicFooter{
        width: 96%;
        margin: 0 auto;
        padding: 50px 0;
        display: flex;
        justify-content: space-around;
        button{
            width: 340px;
            height: 90px;
            font-size: 36px;
            border-radius: 10px;
            i{
                margin-right: 20px;
            }
            &:nth-of-type(1){
                color: #666666;
                background-color: #ffffff;
                border: 1px solid #D2D2D2;
            }
            &:nth-of-type(2){
                color: #FFFFFF;
                background-color: #005D9D;
                border: 1px solid #005D9D;
            }
        }
    }
    .openVote{
        width: 96%;
        margin: 20px auto;
        span{
            margin-right: 20px;
            margin-left: 10px;
        }
    }
}
.popList{
    .title{
        font-size: 30px;
        color: #333333;
        span{
            float: right;
            cursor: pointer;
        }
    }
}
.settingInfo{
  width: 100%;
  min-height:calc(100vh - 100px);
  padding-top: 100px;
  padding-bottom: 20px;
  background-color: #f5f5f5;
  .clear{
    font-size: 30px;
    color: #005D9D;
  }
  &_list{
    width: 96%;
    margin: 0 auto;
    &_item{
      width: 100%;
      margin-top: 40px;
      &>p{
        width: 100%;
        margin: 0;
        margin-bottom: 32px;
        font-size: 24px;
        color: #bdbdbd;
        text-align: center;
      }
      .content{
        width: 100%;
        display: flex;
        .left{
          width: 80px;
          height: 80px;
          img{
            width: 80px;
            height: 80px;
          }
        }
        .right{
          width: calc(100vw - 180px);
          box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.1);
          margin-left: 40px;
          background-color: #ffffff;
          border-radius: 10px;
          padding: 38px 38px 20px;
          box-sizing: border-box;
          position: relative;
          img{
            width: 25px;
            height: 25px;
            position: absolute;
            left: -25px;
            top: 30px;
          }
          p{
            width: 100%;
            margin: 0;
            font-size: 28px;
            color: #000000;
            line-height: 46px;
          }
          span{
            display: block;
            margin-top: 10px;
            width: 100%;
            text-align: right;
            color: #005d9d;
          }
        }
      }
    }
  }
}
@import "../../styles/hairline.less";

.topic{
    width: 100%;
    padding-top: 7vh;
    background-color: @htmlBackgrount;
    .adm-pull-to-refresh{
        width: 100%;
        height: 100%;
    }
    .selectList{
        width: 100%;
        max-width: @innerWidth;
        padding: 17px 22px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: fixed;
        top: 7vh;
        z-index: 9999999;
        margin: 0 auto;
        box-sizing: border-box;
        background: #F5F5F5;
    }
    .main_content{
        width: 100%;
        height: auto;
        margin-top: 110px;
        padding-bottom: 110px;
        .list_item{
            width: 100%;
            height: auto;
            box-sizing: border-box;
            background-color: #ffffff;
            margin-bottom: 20px;
            cursor: pointer;
            &:nth-last-of-type(1){
                border-bottom: 50px solid #F5F5F5;
            }
        }
    }
    .topic_footer{
        width: 100%;
        max-width: @innerWidth;
        height: 110px;
        background: #005D9D;
        border-radius: 10px 10px 0px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 36px;
        position: fixed;
        bottom: 0;
        z-index: 999999;
        border: none;
        i{
            font-weight: bold;
            font-size: 40px;
            margin-right: 27px;
        }
    }
}
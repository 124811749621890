@import "./hairline.less";
@import './__normalize.css';

@font-face {
  font-family: 'iconfont';  /* Project id 3290741 */
  src: url('//at.alicdn.com/t/font_3290741_7cud75vf83e.woff2?t=1654067068954') format('woff2'),
       url('//at.alicdn.com/t/font_3290741_7cud75vf83e.woff?t=1654067068954') format('woff'),
       url('//at.alicdn.com/t/font_3290741_7cud75vf83e.ttf?t=1654067068954') format('truetype');
}

.iconfont{
  font-family:"iconfont" !important;
  font-style:normal;
}


html, body, #root {
  width: 100%;
  height: 100%;
}
html{
  background-color: #e7e3e3;
}
body{
  max-width: @innerWidth;
  margin: 0 auto;
}
.main_loading{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.author_tag{
  border-style: solid;
  border-width: 1px;
  margin-left: 10px !important;
  display: inline-block;
  border-radius: 3px !important;
  text-align: center;
  padding: 9px 10px !important;
  line-height: 0;
  font-size: 20px !important;
  line-height: 18px !important;
  &.success{
    color: #52c41a !important;
    background: #f6ffed;
    border-color: #b7eb8f;
  }
  &.warn{
    color: #faad14 !important;
    background: #fffbe6;
    border-color: #ffe58f;
  }
  &.error{
    color: #ff4d4f !important;
    background: #fff2f0;
    border-color: #ffccc7;
  }
  &.processing{
    color: #1890ff !important;
    background: #e6f7ff;
    border-color: #91d5ff;
  }
  &.purple{
    color: #fff !important;
    background: rgb(16, 142, 233);
    // border-color: #d3adf7;
  }
  &.remote{
    color: #531dab !important;
    background: #f9f0ff;
    border-color: #d3adf7;
  }
}
@import "../../styles/hairline.less";
.topicDetail{
    width: 100%;
    min-height:calc(100vh - 240px);
    padding-top: 100px;
    padding-bottom: 140px;
    background-color: #f5f5f5;
    .header{
        width: 100%;
        height: auto;
        padding: 32px 35px 0;
        box-sizing: border-box;
        background-color: #ffffff;
        .author{
            width: 100%;
            height: 80px;
            display: flex;
            align-items: center;
            .img{
                width: 80px;
                height: 80px;
                border-radius: 50%;
                position: relative;
                .headimg{
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                }
                .vip{
                    width: 29px;
                    height: 29px;
                    position: absolute;
                    right: -5px;
                    bottom: -5px;
                }
            }
            .right{
                margin-left: 20px;
                .name{
                    height: 30px;
                    font-size: 30px;
                    color: #000000;
                    display: flex;
                    align-items: center;
                    p{
                        display: inline-block;
                        background-color: #C40B44;
                        border-radius: 3px;
                        color: #ffffff;
                        text-align: center;
                        padding:10px;
                        line-height: 0;
                        margin-left: 11px;
                        font-size: 20px;
                        line-height: 20px;
                    }
                }
                .time{
                    margin-top: 12px;
                    font-size: 24px;
                    color: #7f7f7f;
                }
            }
        }
        .title{
            border-top: 1px solid #E5E5E5;
            padding-top: 38px;
            margin-top: 29px;
            width: 100%;
            font-size: 40px;
            font-weight: bold;
            color: #000000;
            line-height: 58px;
        }
        .desc{
            padding: 40px 0;
            font-size: 32px;
            font-family: SourceHanSansCN;
            font-weight: 400;
            color: #333333;
            line-height: 52px;
        }
    }
    
    .topic_info{
        background: linear-gradient(rgba(0, 93, 157, 0.04), #ffffff);
        .pk{.pk()}
        .desc{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            &>div{
                width: 40%;
                display: flex;
                &:nth-of-type(1){
                    justify-content: flex-end;
                    p{
                        background-color: #ffffff;
                        border: 1px solid #005D9D;
                        color: #005D9D;
                        &.active{
                            background-color: #005d9d;
                            color: #ffffff;
                        }
                    }
                }
                &:nth-of-type(2){
                    justify-content: flex-start;
                    p{
                        background-color: #ffffff;
                        border: 1px solid #CA1848;
                        color: #CA1848;
                        &.active{
                            background-color: #CA1848 !important;
                            color: #ffffff;
                        }
                    }
                }
            }
            img{
                width: 66px;
                height: 66px;
                margin: 0 43px;
                border-radius: 50%;
            }
            p{
                min-width: 184px;
                max-width: 80%;
                padding: 0 20px;
                // text-overflow: ellipsis;
                // white-space: nowrap;
                // height: 72px;
                background: #005D9D;
                border: 2px solid #005D9D;
                border-radius: 10px;
                overflow: hidden;
                font-size: 36px;
                color: #ffffff;
                line-height: 72px;
                text-align: center;
                &:nth-of-type(1){
                    background-color: #ffffff;
                    border: 1px solid #005D9D;
                    color: #005D9D;
                    &.active{
                        background-color: #005d9d;
                        color: #ffffff;
                    }
                }
            }
        }
        .footer{
            padding-bottom: 44px;
            width: 100%;
            box-sizing: border-box;
            padding-right: 30px;
            text-align: right;
            font-size: 24px;
            color: #BDBDBD;
            span{
                margin-left: 30px;
            }
        }
        .list{
            padding:30px 30px 10px;
            box-sizing: border-box;
            .list()
        }
    }
    .comment{
        width: 96%;
        margin: 28px auto;
        border-radius: 16px;
        background-color: #ffffff;
        .tabs{
            position: relative;
            // top: 90px;
            // z-index: 99;
            .onlooker{
                position: absolute;
                top: 0;
                right: 30px;
                line-height: 96px;
                font-size: 28px;
                color: #999999;
            }
        }
        .adm-tabs{
            background-color: #f5f9fb;
            border-radius: 16px 16px 0 0;
            padding-left: 20px;
            padding-top: 6px;
        }
        .adm-tabs-tab-list{
            height: 87px;
            color: #005d9d;
            box-sizing: border-box;
            .adm-tabs-tab{
                font-size: 28px !important;
            }
            .adm-tabs-tab-active{
                color: #005d9d !important;
                font-weight: bold;
            }
            .adm-tabs-tab-wrapper-stretch{
                flex: none !important;
            }
        }
    }
    .commentItem{
        &:nth-last-of-type(1){
            .footer{
                border: none;
            }
        }
    }
    .add_one{
        width: 40px;
        height: 40px;
        position: absolute;
        top: 30px;
        right: -10px;
        font-size: 25px;
        color: #C40B44;
        font-weight: bold;
        // transition: all 0.4s linear;
        &.active{
            animation: add_one 0.8s linear;
            animation-fill-mode: forwards;
        }
    }
    .topic_detail_footer{
        position: fixed;
        bottom: 0;
        z-index: 99;
        width: 100%;
        max-width: @innerWidth;
        height: 114px;
        display: flex;
        justify-content: center;
        padding: 10px 3% 0;
        box-sizing: border-box;
        background-color: @htmlBackgrount;
        button{
            margin: 0 10px;
            .button();
        }
    }
    .topic_success{
        width: 96%;
        margin: 28px auto;
        box-sizing: border-box;
        padding: 40px;
        border-radius: 16px;
        background-color: #ffffff;
        p{
            color: #49C15E;
            font-size: 36px;
            margin: 0;
        }
        .topic_t{
            margin-top: 26px;
            font-size: 30px;
            color: #7F7F7F;
            span{
                color: #005D9D;
            }
        }
        .textarea{
            width: 100%;
            height: 400px;
            background: #F6F6F6;
            border-radius: 10px;
            font-size: 30px;
            margin-top: 42px;
            textarea{
                padding: 20px;
                box-sizing: border-box;
            }
        }
    }
}

.button{
    width:49%;
    height: 90px;
    display: flex;
    align-items: center;
    font-size: 36px;
    justify-content: center;
    color: #ffffff;
    background-color: #005D9D;
    border-radius: 6px;
    i{
        margin-right: 10px;
    }
    &.nobg{
        background-color: #ffffff;
        color: #005D9D;
        border: 1px solid #005D9D;
    }
}

.list{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    .item_box{
        width: 100%;
        // height: 82px;
        border-radius: 10px;
        // overflow: hidden;
        position: relative;
        margin-bottom: 20px;
        &.active{
            .line_bg div{
                background-color: #dff7f5;
            }
            .item{
                border: 1px solid #87e2da;
                .num{
                    color: #04c2b2;
                }
            }
        }
        .line_bg{
            width: 30%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            box-sizing: border-box;
            div{
                width: 100%;
                height: 100%;
                background-color: #f1f1f1;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }
        }
        .item{
            position: relative;
            z-index: 2;
            border-radius: 10px;
            border: 1px solid #D2D2D2;
            overflow: hidden;
            width: 100%;
            line-height: 40px;
            padding: 30px 34px;
            box-sizing: border-box;
            font-size: 30px;
            color: #333333;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
            .text{
                width: 60%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
            }
        }
    }
}

.pk{

    width: 100%;
    // max-width: 1000px;
    display: flex;
    align-items: center;
    padding: 40px 30px 0 30px;
    box-sizing: border-box;
    .num{
        font-size: 24px;
        font-weight: normal;
        color: #005d9d;
    }
    .line_box{
        height: 20px;
        flex: 1;
        padding:0 28px;
        position: relative;
        .line{
            height: 100%;
            border-radius: 10px;
            float: left;
            position: relative;
            img{
                width: 18px;
                height: 33px;
                position: absolute;
                right: -10px;
                top: -7px;
                z-index: 99;
            }
            &.borderReader{
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
            &.borderReader2{
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
            &:nth-of-type(1){
                // width: 33%;
                // border-top-right-radius: 0;
                // border-bottom-right-radius: 0;
                background: linear-gradient(-90deg, #43A7EC, #005D9D);
            }
            &:nth-of-type(2){
                // width: 66%;
                // border-top-left-radius: 0;
                // border-bottom-left-radius: 0;
                background: linear-gradient(-90deg, #CA1848, #FF507F);
            }
        }
    }
}


.popList{
    width: 100%;
    max-width: @innerWidth;
    max-height: 80vh;
    overflow-y: auto;
    margin: 0 auto;
    padding: 38px 30px;
    box-sizing: border-box;
    .title{
        width: 100%;
        font-size: 40px;
        font-weight: bold;
        color: #000000;
        line-height: 58px;
        box-sizing: border-box;
    }
    .desc{
        padding-bottom: 44px;
        margin-top: 20px;
        width: 100%;
        box-sizing: border-box;
        padding-right: 30px;
        text-align: left;
        font-size: 24px;
        color: #BDBDBD;
        span{
            margin-left: 30px;
        }
    }
    .list{
        .list();
        .item_box{
            cursor: pointer;
        }
    }
    .pk{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        button{
            width: 100%;
            height: 102px;
            font-size: 36px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            padding:0 30px;
            border-radius: 6px;
            span{
                color: #999999;
                font-size: 24px;
            }
            &:nth-of-type(1){
                margin-bottom: 20px;
                border: 1px solid #005D9D;
                color: #005D9D;
                &.select{
                    background-color: #005D9D;
                    color: #ffffff;
                }
            }
            &:nth-of-type(2){
                border: 1px solid #C40B44;
                color: #C40B44;
                &.select{
                    background-color: #C40B44;
                    color: #ffffff;
                }
            }
        }
    }
    .topic_button{
        margin: 42px auto 40px;
        .button()
    }
    p{
        color: #979797;
        font-size: 24px;
        text-align: center;
    }
}


@keyframes add_one {
    from {
        top: 30px;
    }
    to {
        top:-40px;
    }
}
.pointViewDetailItem{
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 26px 28px 0;
    background-color: #F6F6F6;
    // border-radius: 10px;
    // margin-bottom: 30px;
    display: flex;
    .head_img{
        width: 44px;
        height:44px;
        border-radius: 50%;
    }
    .right{
        width: 100%;
        padding-left: 16px;
        box-sizing: border-box;
        &>p{
            width: 100%;
            font-size: 28px;
            color: #7f7f7f;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0;
            position: relative;
            line-height: 46px;
            span>a{
                color: #ffffff;
                background-color: #C40B44;
                font-size: 20px;
                padding: 6px 10px;
                margin-left: 15px;
                border-radius: 3px;
            }
            img{
                position: absolute;
                right: 0;
                width: 92px;
                height: 48px;
                margin-top: -10px;
            }
            li{
                display: flex;
                align-items: center;
                cursor: pointer;
                i{
                    &.y{
                        font-size: 36px;
                    }
                    margin-right: 9px;
                    &:nth-of-type(1){
                        display: inline-block;
                    }
                    &:nth-of-type(2){
                        display: none;
                    }
                }
                &.active{
                    color: #005d9d;
                    i{
                        &:nth-of-type(2){
                            display: inline-block;
                        }
                        &:nth-of-type(1){
                            display: none;
                        }
                    }
                   
                }
            }
        }
        .comment_content{
            font-size: 28px;
            color: #333333;
            line-height: 44px;
            margin-top: 16px;
            margin-bottom: 20px;
        }
    }
    .footer{
        // width: 100%;
        text-align: right;
        font-size: 24px;
        cursor: pointer;
        color: #bdbdbd;
        margin-bottom: 20px;
        float: right;
    }
    .topic_success{
        padding: 0 !important;
        margin-top: 50px !important;
        background: #ffffff !important;
        border-radius: 0 !important;
        .textarea{
            background: #ffffff !important;
        }
    }
    .topic_success_footer{
        margin-top: 0 !important;
        border-top: 10px solid #f6f6f6;
    }
}
.pointViewItem{
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 26px 28px 0;
    display: flex;
    .head_img{
        width: 44px;
        height:44px;
        border-radius: 50%;
    }
    &>.right{
        width: 100%;
        padding-left: 16px;
        box-sizing: border-box;
        border-bottom: 1px solid #E5E5E5;
        &>p{
            width: 100%;
            font-size: 28px;
            color: #7f7f7f;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0;
            position: relative;
            line-height: 46px;
            span>a{
                color: #ffffff;
                background-color: #C40B44;
                font-size: 20px;
                padding: 6px 10px;
                margin-left: 15px;
                border-radius: 3px;
            }
            img{
                position: absolute;
                right: 0;
                width: 92px;
                height: 48px;
                margin-top: -10px;
            }
            li{
                display: flex;
                align-items: center;
                cursor: pointer;
                i{
                    &.y{
                        font-size: 36px;
                    }
                    margin-right: 9px;
                    &:nth-of-type(1){
                        display: inline-block;
                    }
                    &:nth-of-type(2){
                        display: none;
                    }
                }
                &.active{
                    color: #005d9d;
                    i{
                        &:nth-of-type(2){
                            display: inline-block;
                        }
                        &:nth-of-type(1){
                            display: none;
                        }
                    }
                   
                }
            }
        }
        .comment_content{
            font-size: 28px;
            color: #333333;
            line-height: 44px;
            margin-top: 16px;
            margin-bottom: 20px;
        }
    }
    .footer{
        // width: 100%;
        text-align: right;
        font-size: 24px;
        cursor: pointer;
        color: #bdbdbd;
        margin-bottom: 20px;
        float: right;
    }
    .topic_success{
        padding: 0 !important;
        margin-top: 50px !important;
    }
    .topic_success_footer{
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin-top: 30px;
        .button{
            width:49%;
            height: 80px !important;
            display: flex;
            align-items: center;
            font-size: 30px !important;
            justify-content: center;
            color: #ffffff;
            background-color: #005D9D;
            border-radius: 6px;
            i{
                margin-right: 10px;
            }
            &.nobg{
                background-color: #ffffff;
                color: #005D9D;
                border: 1px solid #005D9D;
            }
        }
    }
    .comment_detail{
        .pointViewDetailItem:nth-last-of-type(1){
            margin-bottom: 30px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
        .pointViewDetailItem:nth-of-type(1){
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
    }
}
.voteItem{
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 26px 28px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &>div{
        width: auto;
        max-width: 50%;
        display: flex;
        align-items: center;
    }
    .head_img{
        width: 44px;
        height:44px;
        border-radius: 50%;
    }
    .name{
        font-size: 28px;
        color: #7F7F7F;
        margin: 0 0 0 22px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .desc{
        max-width: 50%;
        font-size: 28px;
        color: #000000;
        margin: 0;
        display: flex;
        align-items: center;
        text-align: right;
        span{
            color: #BDBDBD;
        }
    }
}
.topicItem{
    width: 100%;
    height: auto;
    padding: 40px 0px 0px;
    box-sizing: border-box;
    cursor: pointer;
    .title{
        font-size: 40px;
        font-weight: bold;
        color: #000000;
        line-height: 58px;
        margin: 0px 0 0px;
    }
    .topicItem_author_list{
        margin-top: 29px;
        width: 100%;
        box-sizing: border-box;
        .author_list_item{
            width: 100%;
            min-height: 55px;
            display: flex;
            align-items: center;
            font-size: 24px;
            color: #7F7F7F;
            box-sizing: border-box;
            padding-left: 6px;
            img{
                width: 44px;
                border-radius: 50%;
                margin-left: 19px;
                margin-right: 9px;
            }
            span{
                color: #7F7F7F;
            }
            .hot{
                margin-right: 23px;
            }
            p{
                display: inline-block;
                background-color: #C40B44;
                border-radius: 6px;
                color: #ffffff;
                text-align: center;
                padding: 20px 10px;
                line-height: 0;
                margin-left: 11px;
                font-size: 20px;
            }
        }
    }
    .topicItem_footer{
        padding-bottom: 10px;
        border-bottom: 1px solid #E5E5E5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        button{
            height: 68px;
            font-size: 28px;
            color: #ffffff;
            background-color: #005D9D;
            line-height: 28px;
            i{
                font-size: 30px;
            }
        }
        p{
            flex: auto;
            font-size: 28px;
            text-align: right;
            i{
                border: 1px solid;
                box-sizing: border-box;
                padding: 2px;
                font-size: 20px;
                border-radius: 3px;
            }
            &.success{
                color: #49C15E;
                i{
                    border-color:#49C15E;
                }
            }
            &.loading{
                color: #669BBF;
                i{
                    border-color:#669BBF;
                }
            }
            &.error{
                color: #BBBBBB;
                i{
                    border-color:#BBBBBB;
                }
            }
            
        }
        
    }
}
.createselect{
    // padding: 0 23px;
    min-width: 224px;
    width: 100%;
    // height: 66px;
    background: #FFFFFF;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    .input_select{
        width: 0px;
        height: 0px;
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;
    }
    p{
        color: #888888;
        font-size: 28px;
        padding: 0 23px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0;
        i.down{
            font-weight: bold;
            font-size: 30px;
            transition: all  0.2s linear;
        }
        .clear{
            font-size: 26px;
        }
    }
    &>ul{
        display: none;
        list-style: none;
    }
    &.active{
        border-radius: 0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        i.down{
            transform: rotate(180deg);
        }
        ul{
            display: block;
        }
    }
    ul{
        margin: 0;
        padding: 0;
        list-style: none;
        position: absolute;
        z-index: 99;
        left: 0;
        top: 80px;
        width: 100%;
        height: auto;
        max-height: 500px;
        overflow: auto;
        background-color: #ffffff;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top: 1px solid #e5e5e5;
        box-shadow: 6px 10px 20px 0px rgba(0, 0, 0, 0.1);
        li{
            width: 100%;
            height: 80px;
            line-height: 80px;
            font-size: 28px;
            color: #000000;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0 23px;
            box-sizing: border-box;
            &.li_active{
                background-color: #f5f5f5;
            }
            &:hover{
                background-color: #f5f5f5;
            }
        }
    }
}
.battleTopic{
    width: 100%;
    height: auto;
    padding: 40px 0 30px;
    box-sizing: border-box;
    .title_icon_top{
        margin-left: 30px;
    }
    .title_icon{
        height: 60px;
        background: #f1f6f9;
        color: #005d9d;
        border-radius: 16px;
        // display: flex;
        align-items: center;
        margin-bottom: 36px;
        margin-left: 30px;
        padding: 0 20px 0px 0;
        display: inline-block;
        line-height: 60px;
        img{
            width: 37px;
            height: 36px;
            margin-right: 15px;
            margin-left: 16px;
            float: left;
            margin-top: 10px;
        }
    }
    .title{
        font-size: 40px;
        font-weight: bold;
        color: #000000;
        line-height: 58px;
        margin: 0px 0 30px;
        padding: 0 30px 0 30px;
        box-sizing: border-box;
    }
    .pk_ho{
        width: 100%;
        // max-width: 1000px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        padding: 0 30px 0 30px;
        box-sizing: border-box;
        .num{
            font-size: 24px;
            font-weight: normal;
            color: #005d9d;
        }
        .line_box{
            height: 20px;
            flex: 1;
            padding:0 28px;
            position: relative;
            
            .line{
                height: 100%;
                border-radius: 10px;
                float: left;
                position: relative;
                img{
                    width: 18px;
                    height: 33px;
                    position: absolute;
                    top: -7px;
                    right: -10px;
                    z-index: 99;
                }
                &.borderReader{
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
                &.borderReader2{
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
                &:nth-of-type(1){
                    // width: 33%;
                    // border-top-right-radius: 0;
                    // border-bottom-right-radius: 0;
                    background: linear-gradient(-90deg, #43A7EC, #005D9D);
                }
                &:nth-of-type(2){
                    // width: 66%;
                    // border-top-left-radius: 0;
                    // border-bottom-left-radius: 0;
                    background: linear-gradient(-90deg, #CA1848, #FF507F);
                }
            }
        }
    }
    .desc{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 66px;
            height: 66px;
            margin: 0 43px;
        }
        &>div{
            width: 40%;
            display: flex;
            &:nth-of-type(1){
                justify-content: flex-end;
                p{
                    background-color: #ffffff;
                    border: 1px solid #005D9D;
                    color: #005D9D;
                    &.active{
                        background-color: #005d9d;
                        color: #ffffff;
                    }
                }
            }
            &:nth-of-type(2){
                justify-content: flex-start;
                p{
                    background-color: #ffffff;
                    border: 1px solid #CA1848;
                    color: #CA1848;
                    &.active{
                        background-color: #CA1848 !important;
                        color: #ffffff;
                    }
                }
            }
        }
        p{
            min-width: 184px;
            max-width: 80%;
            padding: 0 20px;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap;
            // height: 72px;
            background: #005D9D;
            border: 2px solid #005D9D;
            border-radius: 10px;
            overflow: hidden;
            font-size: 36px;
            color: #ffffff;
            line-height: 72px;
            text-align: center;
        }
    }
    .comment{
        width: 100%;
        display: flex;
        &>div{
            width: 50%;
            height: auto;
            padding: 30px;
            box-sizing: border-box;
            padding-top: 40px;
            .author{
                margin-top: 20px;
                width: 100%;
                font-size: 24px !important;
                font-weight: 400;
                color: #7F7F7F !important;
                display: flex;
                align-items: center;
                img{
                    width: 44px;
                    height: 44px;
                    border-radius: 50%;
                    margin-right: 8px;
                }
                span{
                    color: #BDBDBD;
                    margin-left: 37px;
                }
                .name{
                    max-width: 30%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    color: #7F7F7F;
                    margin-left: 0;
                }
            }
            &.left{
                background: linear-gradient( rgba(0, 93, 157, 0.06), #ffffff);
                .info{
                    width: 100%;
                    border: 1px solid #7faece;
                    padding: 20px;
                    box-sizing: border-box;
                    color: #333333;
                    font-size: 24px;
                    border-radius: 18px;
                    position: relative;
                    background-color: #f6f9fb;
                    &>img{
                        width: 19px;
                        height: 26px;
                        position: absolute;
                        left: 40px;
                        top: -24px;
                    }
                }
            }
            &.right{
                background: linear-gradient( #fcf3f6, #ffffff);
                .info{
                    width: 100%;
                    border: 1px solid #e488a1;
                    padding: 20px;
                    box-sizing: border-box;
                    border-radius: 18px;
                    position: relative;
                    background-color: #fdf5f7;
                    color: #333333;
                    font-size: 24px;
                    &>img{
                        width: 19px;
                        height: 26px;
                        position: absolute;
                        right: 40px;
                        top: -24px;
                    }
                }
            }
        }
    }
    .footer{
        // margin-top: 20px;
        width: 100%;
        box-sizing: border-box;
        padding-right: 30px;
        text-align: right;
        font-size: 24px;
        color: #BDBDBD;
        span{
            margin-left: 30px;
        }
    }
    .author_list{
        margin-top: 29px;
        width: 100%;
        padding: 0 30px;
        box-sizing: border-box;
        border-top: 1px solid #E5E5E5;
        padding-top: 26px;
        .author_list_item{
            width: 100%;
            height: 55px;
            display: flex;
            align-items: center;
            font-size: 24px;
            color: #000000;
            box-sizing: border-box;
            padding-left: 6px;
            img{
                width: 44px;
                border-radius: 50%;
                margin-left: 19px;
                margin-right: 9px;
            }
            span{
                color: #7F7F7F;
            }
            .hot{
                margin-left: 23px;
                margin-right: 10px;
            }
            p{
                display: inline-block;
                background-color: #C40B44;
                border-radius: 6px;
                color: #ffffff;
                text-align: center;
                padding: 20px 10px;
                line-height: 0;
                margin-left: 11px;
                font-size: 20px;
            }
        }
    }
    .topic_text{
        margin: 20px 30px 0;
        // width: 100%;
        // height: 190px;
        background: #F5F5F5;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 30px 27px 0;
        font-size: 28px;
        line-height: 44px;
        color: #333333;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        border-bottom: 15px solid #F5F5F5;
        span{
            color: #005D9D;
        }
    }
}
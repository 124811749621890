.commuityData{
  width: 100%;
  min-height:calc(100vh - 100px);
  padding-top: 90px;
  padding-bottom: 20px;
  background-color: #f5f5f5;
  &_header{
    width: 100%;
    height: auto;
    padding: 35px 37px;
    box-sizing: border-box;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    img{
      width: 120px;
      height: 120px;
      border-radius: 50%;
    }
    span{
      color: #000000;
      font-size: 32px;
      margin-left: 23px;
    }
  }
  .tabs{
    width: 96%;
    margin: 20px auto 0;
    position: relative;
    border-radius: 10px 10px 0px 0px;
    &>.list{
      width: 100%;
      box-sizing: border-box;
      padding:0 3%;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      &>.topicItem:nth-last-of-type(1) .topicItem_footer{
        border: none;
      }
      &>.viewpoint:nth-last-of-type(1){
        border: none;
      }
      &>.collectItemL:nth-last-of-type(1) .topicItem_footer{
        border: none;
      }
      &>.commentItem_B:nth-last-of-type(1){
        border: none;
      }
    }
  }
  .adm-tabs{
      background-color: #f5f9fb;
      border-radius: 16px 16px 0 0;
      padding-left: 20px;
      padding-top: 6px;
  }
  .adm-tabs-tab-list{
      height: 87px;
      color: #005d9d;
      box-sizing: border-box;
      .adm-tabs-tab{
          font-size: 28px !important;
      }
      .adm-tabs-tab-active{
          color: #005d9d !important;
          font-weight: bold;
      }
  }

}
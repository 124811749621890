.commentItem{
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 26px 28px 0;
    display: flex;
    .head_img{
        width: 44px;
        height:44px;
        border-radius: 50%;
    }
    .support{
        width: 80%;
        font-size: 28px;
        color: #7f7f7f;
        display: flex;
        align-items: center;
        margin: 0;
        line-height: 46px;
    }
    .right{
        width: 100%;
        padding-left: 16px;
        box-sizing: border-box;
        &>p{
            width: 100%;
            font-size: 28px;
            color: #7f7f7f;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0;
            position: relative;
            line-height: 46px;
            img{
                position: absolute;
                right: 0;
                width: 92px;
                height: 48px;
                margin-top: -10px;
            }
        }
        .comment_content{
            font-size: 28px;
            color: #333333;
            line-height: 44px;
            margin-top: 16px;
            cursor: pointer;
        }
    }
    .footer{
        font-size: 28px;
        display: flex;
        align-items: center;
        color: #666666;
        margin-top: 48px;
        position: relative;
        padding-bottom: 25px;
        border-bottom: 1px solid #E5E5E5;
        p{
            font-size: 24px;
            color: #bdbdbd;
            margin: 0;
            flex: auto;
            text-align: right;
        }
        span{
            margin-right: 46px;
            display: flex;
            align-items: center;
            cursor: pointer;
            i{
                &.y{
                    font-size: 36px;
                }
                margin-right: 9px;
                &:nth-of-type(1){
                    display: inline-block;
                }
                &:nth-of-type(2){
                    display: none;
                }
            }
            &.active{
                color: #005d9d;
                i{
                    &:nth-of-type(2){
                        display: inline-block;
                    }
                    &:nth-of-type(1){
                        display: none;
                    }
                }
               
            }
        }
        
    }
}
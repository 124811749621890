@import "../../styles/hairline.less";
.pointDetail{
    width: 100%;
    min-height:calc(100vh - 130px);
    padding-top: 100px;
    padding-bottom: 30px;
    background-color: #f5f5f5;
    .header{
        width: 100%;
        height: auto;
        padding: 32px 35px 0;
        box-sizing: border-box;
        background-color: #ffffff;
        .author{
            width: 100%;
            height: 80px;
            display: flex;
            align-items: center;
            .img{
                width: 80px;
                height: 80px;
                border-radius: 50%;
                position: relative;
                .headimg{
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                }
                .vip{
                    width: 29px;
                    height: 29px;
                    position: absolute;
                    right: -5px;
                    bottom: -5px;
                }
            }
            .right{
                margin-left: 20px;
                .name{
                    height: 30px;
                    font-size: 30px;
                    color: #000000;
                    display: flex;
                    align-items: center;
                    p{
                        display: inline-block;
                        background-color: #C40B44;
                        border-radius: 3px;
                        color: #ffffff;
                        text-align: center;
                        padding:10px;
                        line-height: 0;
                        margin-left: 11px;
                        font-size: 20px;
                        line-height: 20px;
                    }
                }
                .time{
                    margin-top: 12px;
                    font-size: 24px;
                    color: #7f7f7f;
                }
            }
        }
        .title{
            border-top: 1px solid #E5E5E5;
            padding-top: 38px;
            margin-top: 29px;
            width: 100%;
            font-size: 40px;
            font-weight: bold;
            color: #000000;
            line-height: 58px;
        }
        .desc{
            padding: 40px 0;
            font-size: 32px;
            font-family: SourceHanSansCN;
            font-weight: 400;
            color: #333333;
            line-height: 52px;
        }
        .voteInfo{
            color: #7f7f7f;
            font-size: 30px;
            margin-top: 40px;
            span{
                color: #005D9D;
            }
        }
        .header_book{
            width: 100%;
            font-size: 24px;
            color: #bdbdbd;
            padding-bottom: 30px;
        }
    }
    .tab_list{
        width: 100%;
        height: 108px;
        border-top: 1px solid #E5E5E5;
        background-color: #ffffff;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: space-between;
        padding:23px 65px;
        li{
            list-style: none;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            cursor: pointer;
            &.active{
                color: #005d9d;
                i{
                    &:nth-of-type(2){
                        display: inline-block;
                    }
                    &:nth-of-type(1){
                        display: none;
                    }
                }
               
            }
            i{
                font-size: 30px;
                &.y{
                    font-size: 36px;
                }
                &:nth-of-type(2){
                    display: none;
                }
            }
            p{
                width: 100%;
                margin: 0;
                text-align: center;
                font-size: 24px;
                margin-top: 10px;
            }
        }
    }
    .comment{
        width: 96%;
        background-color: #ffffff;
        margin: 20px auto;
        border-radius: 10px;
        .top{
            width: 100%;
            height: 87px;
            line-height: 87px;
            font-size: 30px;
            color: #6b6b6b;
            padding:0 20px;
            box-sizing: border-box;
            background-color: #f5f9fb;
            border-radius: 10px 10px 0px 0px;
            p{
                margin: 0;
                margin-top: 13px;
                float: right;
                background-color: #005D9D;
                color: #ffffff;
                padding: 0 30px;
                border-radius: 25px;
                line-height: 60px;
                cursor: pointer;
            }
        }
        .item_comment{
            &:nth-last-of-type(1){
                .pointViewItem >.right{
                    border: none;
                }
            }
        }
    }
    .topic_success{
        width: 96%;
        margin: 28px auto;
        box-sizing: border-box;
        padding: 40px;
        border-radius: 16px;
        background-color: #ffffff;
        p{
            color: #49C15E;
            font-size: 36px;
            margin: 0;
        }
        .topic_t{
            margin-top: 26px;
            font-size: 30px;
            color: #7F7F7F;
            span{
                color: #005D9D;
            }
        }
        .textarea{
            width: 100%;
            height: 400px;
            background: #F6F6F6;
            border-radius: 10px;
            font-size: 30px;
            textarea{
                padding: 20px;
                box-sizing: border-box;
            }
        }
        .topic_success_footer{
            width: 100%;
            display: flex;
            justify-content: space-around;
            margin-top: 30px;
            .button{
                width:49%;
                height: 90px;
                display: flex;
                align-items: center;
                font-size: 36px;
                justify-content: center;
                color: #ffffff;
                background-color: #005D9D;
                border-radius: 6px;
                i{
                    margin-right: 10px;
                }
                &.nobg{
                    background-color: #ffffff;
                    color: #005D9D;
                    border: 1px solid #005D9D;
                }
            }
        }
        
    }
}
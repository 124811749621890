.basicsTopic{
    width: 100%;
    height: auto;
    padding: 40px 30px 30px;
    box-sizing: border-box;
    .title{
        font-size: 40px;
        font-weight: bold;
        color: #000000;
        line-height: 58px;
        margin: 0px 0 30px;
    }
    .author{
        margin-bottom: 30px;
        width: 100%;
        font-size: 24px;
        font-weight: 400;
        color: #7F7F7F;
        display: flex;
        align-items: center;
        img{
            width: 44px;
            height: 44px;
            border-radius: 50%;
            margin-right: 8px;
        }
        span{
            color: #BDBDBD;
            margin-left: 37px;
        }
    }
    .author_list{
        width: 100%;
        box-sizing: border-box;
        border-top: 1px solid #E5E5E5;
        padding-top: 26px;
        .author_list_item{
            width: 100%;
            height: 55px;
            display: flex;
            align-items: center;
            font-size: 24px;
            color: #000000;
            box-sizing: border-box;
            padding-left: 6px;
            img{
                width: 44px;
                border-radius: 50%;
                margin-left: 19px;
                margin-right: 9px;
            }
            span{
                color: #7F7F7F;
            }
            .hot{
                margin-left: 23px;
                margin-right: 10px;
            }
            p{
                display: inline-block;
                background-color: #C40B44;
                border-radius: 6px;
                color: #ffffff;
                text-align: center;
                padding: 20px 10px;
                line-height: 0;
                margin-left: 11px;
                font-size: 20px;
            }
        }
    }
    .topic_text{
        margin-top: 20px;
        width: 100%;
        // height: 190px;
        background: #F5F5F5;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 30px 27px 0;
        font-size: 28px;
        line-height: 44px;
        color: #333333;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        border-bottom: 15px solid #F5F5F5;
        span{
            color: #005D9D;
        }
    }
    .text{
        max-height: 134px;
        font-size: 34px;
        color: #333333;
        line-height: 50px;
        margin-bottom: 30px;
    }
}
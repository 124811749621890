.title_icon_basics_icon{
    // width: 133px;
    height: 60px;
    background: #fdf3f3;
    color: #e43131;
    border-radius: 16px;
    // display: flex;
    align-items: center;
    margin-bottom: 36px;
    padding: 0 20px 0px 0;
    display: inline-block;
    line-height: 60px;
    img{
        width: 37px;
        height: 36px;
        margin-right: 15px;
        margin-left: 16px;
        float: left;
        margin-top: 10px;
    }
}
@import "../../styles/hairline.less";
.tabbar {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%,0);
  z-index: 9000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  max-width: @innerWidth;
  height: 7vh;
  box-sizing: border-box;
  text-align: center;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  .tabbar-item {
    height: 100%;
    font-size: 32px;
    font-weight: 400;
    color: #888888;
    line-height: 7vh;
    position: relative;
    display: flex;
    justify-content: center;
    &::after{
      content: "";
      width: 30px;
      height: 6px;
      background: #005D9D;
      border-radius: 3px;
      position: absolute;
      bottom: 0.6vh;
      display: none;
    }
    &.action{
      font-size: 40px;
      color: #333333;
      &::after{
        display: block;
      }
    }
  }
  &>.back_REDIRECT{
    position: fixed;
    left: 26px;
    font-size: 40px;
    cursor: pointer;
    // top:12px;
    margin:0;
    padding: 0;
  }
}
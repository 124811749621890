.commentItem_B{
    width: 100%;
    height: auto;
    padding: 40px 0px 32px;
    box-sizing: border-box;
    border-bottom: 1px solid #E5E5E5;
    cursor: pointer;
    .title{
        font-size: 30px;
        font-weight: 400;
        color: #000000;
        line-height: 50px;
        margin: 0px 0 20px;
    }
    .topic_text{
        margin-top: 20px;
        width: 100%;
        // height: 190px;
        background: #F5F5F5;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 20PX 27px 0;
        font-size: 26px;
        line-height: 44px;
        color: #7F7F7F;
        overflow: hidden;
        // display: -webkit-box;
        // -webkit-line-clamp: 3;
        // text-overflow: ellipsis;
        // -webkit-box-orient: vertical;
        border-bottom: 10px solid #F5F5F5;
        .left{
            display: block;
            height: 44px;
            float: left;
            margin-right: 8px;
            img{
                width: 44px;
                height: 44px;
                border-radius: 50%;
            }
        }
        span{
            color: #005D9D;
        }
        
    }
}
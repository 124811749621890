@import "../../styles/hairline.less";
.navbar {
  width: 100%;
  max-width: @innerWidth;
  height: 90px;
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: #ffffff;
  color: #000000;
  font-size: 44px;
  font-weight: 500;
  border-bottom: 1px solid rgba(0,0,0,.1);
}
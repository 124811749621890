.mina-share{
  width:80px;
  height:80px;
  box-sizing: border-box;
  display:flex;
  justify-content:center;
  align-items:center;
  border-radius:50%;
  background: linear-gradient(#54ade9, #295f9e);
  color:#fff;
  margin-bottom:10px;
  .iconfont{
    font-size:24px;
  }
}
.my{
    height: 100%;
    padding-top: 7vh;
    background-color: #ffffff;
    .page {
        background-color: rgba(255, 255, 255, 1);
        position: relative;
        overflow: hidden;
      }
      
      .mod1 {
        background-color: rgba(255, 255, 255, 1);
        height: 275px;
      }
      
      .box2 {
        height: 243px;
        margin-top: 32px;
      }
      
      .img1 {
        height: 24px;
        margin-left: 25px;
      }
      
      .word1 {
        width: 70px;
        height: 34px;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 36px;
        font-family: SourceHanSansCN-Medium;
        text-align: left;
        white-space: nowrap;
        line-height: 36px;
        display: block;
        margin: 59px 0 0 340px;
      }
      
      .outer1 {
        width: 521px;
        height: 48px;
        margin: 47px 0 0 131px;
      }
      
      .word2 {
        width: 62px;
        height: 30px;
        overflow-wrap: break-word;
        color: rgba(136, 136, 136, 1);
        font-size: 32px;
        font-family: SourceHanSansCN-Regular;
        text-align: left;
        white-space: nowrap;
        line-height: 32px;
        margin-top: 14px;
        display: block;
      }
      
      .word3 {
        width: 62px;
        height: 30px;
        overflow-wrap: break-word;
        color: rgba(136, 136, 136, 1);
        font-size: 32px;
        font-family: SourceHanSansCN-Regular;
        text-align: left;
        white-space: nowrap;
        line-height: 32px;
        display: block;
        margin: 15px 0 0 86px;
      }
      
      .word4 {
        width: 62px;
        height: 30px;
        overflow-wrap: break-word;
        color: rgba(136, 136, 136, 1);
        font-size: 32px;
        font-family: SourceHanSansCN-Regular;
        text-align: left;
        white-space: nowrap;
        line-height: 32px;
        display: block;
        margin: 14px 0 0 81px;
      }
      
      .word5 {
        width: 77px;
        height: 38px;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 40px;
        font-family: SourceHanSansCN-Bold;
        text-align: left;
        white-space: nowrap;
        line-height: 40px;
        display: block;
        margin: 10px 0 0 72px;
      }
      
      .section1 {
        background-color: rgba(196, 11, 68, 1);
        border-radius: 50%;
        width: 12px;
        height: 12px;
        margin-left: 7px;
      }
      
      .outer2 {
        background-color: rgba(0, 93, 157, 1);
        border-radius: 3px;
        width: 30px;
        height: 6px;
        margin: 10px 0 0 580px;
      }
      
      .outer3 {
        background-color: rgba(0, 0, 0, 0.15);
        width: 750px;
        height: 1px;
        margin-top: 14px;
      }
      
      .mod2 {
        background-color: rgba(238, 244, 248, 1);
        border-radius: 16px;
        z-index: 15;
        height: 337px;
        overflow: hidden;
        width: 96%;
        position: relative;
        cursor: pointer;
        // margin: 33px 0 0 25px;
        margin: 33px auto 25px;
      }
      
      .group1 {
        width: 100%;
        height: 337px;
      }
      
      .group2 {
        width: 100%;
        height: 144px;
        background: url(../../static/image/pssqbwbriuhghfc9ced1nub5kqggr4166ohe919ae3e-18e0-4bb3-8dc6-316a6ba88565.png)
          0px -293px no-repeat;
        margin-left: 141px;
      }
      
      .group3 {
        background-color: rgba(0, 93, 157, 0.03);
        border-radius: 16px;
        height: 130px;
        margin-top: 63px;
        width: 100%;
      }
      
      .main1 {
        width: 100%;
        height: 79px;
        justify-content: space-between;
        margin: 27px auto 64px;
        padding: 0 70px;
        box-sizing: border-box;
      }
      
      .main2-0 {
        height: 79px;
        justify-content: center;
        // margin-right: 118px;
      }
      
      .word6-0 {
        overflow-wrap: break-word;
        color: #005d9d;
        font-size: 44px;
        font-family: Roboto-Bold;
        text-align: left;
        white-space: nowrap;
        line-height: 44px;
        margin-left: 4px;
        display: block;
      }
      
      .word7-0 {
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 28px;
        font-family: SourceHanSansCN-Regular;
        text-align: left;
        white-space: nowrap;
        line-height: 28px;
        margin-top: 22px;
        display: block;
      }
      
      .main2-1 {
        height: 79px;
        // margin-right: 118px;
      }
      
      .word6-1 {
        overflow-wrap: break-word;
        color: #005d9d;
        font-size: 44px;
        font-family: Roboto-Bold;
        text-align: left;
        white-space: nowrap;
        line-height: 44px;
        margin-left: 4px;
        display: block;
      }
      
      .word7-1 {
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 28px;
        font-family: SourceHanSansCN-Regular;
        text-align: left;
        white-space: nowrap;
        line-height: 28px;
        margin-top: 22px;
        display: block;
      }
      
      .main2-2 {
        height: 79px;
        // margin-right: 118px;
      }
      
      .word6-2 {
        overflow-wrap: break-word;
        color: #005d9d;
        font-size: 44px;
        font-family: Roboto-Bold;
        text-align: left;
        white-space: nowrap;
        line-height: 44px;
        margin-left: 4px;
        display: block;
      }
      
      .word7-2 {
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 28px;
        font-family: SourceHanSansCN-Regular;
        text-align: left;
        white-space: nowrap;
        line-height: 28px;
        margin-top: 22px;
        display: block;
      }
      
      .main2-3 {
        height: 79px;
        // margin-right: 118px;
      }
      
      .word6-3 {
        overflow-wrap: break-word;
        color: #005d9d;
        font-size: 44px;
        font-family: Roboto-Bold;
        text-align: left;
        white-space: nowrap;
        line-height: 44px;
        margin-left: 4px;
        display: block;
      }
      
      .word7-3 {
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 28px;
        font-family: SourceHanSansCN-Regular;
        text-align: left;
        white-space: nowrap;
        line-height: 28px;
        margin-top: 22px;
        display: block;
      }
      
      .group4 {
        z-index: 17;
        height: 190px;
       
        // width: 663px;
        position: absolute;
        left: 37px;
        top: 0;
      }
      
      .box3 {
        width: 266px;
        height: 120px;
        margin: 42px 0 0 3px;
      }
      
      .SingleAvatar1 {
        background-color: rgba(69, 69, 69, 1);
        border-radius: 50%;
        width: 120px;
        height: 120px;
        img{
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
      }
      
      .word8 {
        cursor: pointer;
        width: 123px;
        height: 31px;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 32px;
        font-family: AlibabaPuHuiTiM;
        text-align: left;
        white-space: nowrap;
        line-height: 32px;
        margin-top: 44px;
        display: block;
      }
      
      .mod3 {
        cursor: pointer;
        width: 90%;
        height: 44px;
        margin: 50px auto 27px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        i{
            font-size: 40px;
        }
      }
      
      .bd1 {
        width: 37px;
        height: 42px;
        background: url(../../static/image/201dd86f5d3d4bc7b8f2a1140abb452a_mergeImage.png) no-repeat;
        background-size: 37px 42px;
        .flex_center()
      }
      .bd2 {
        width: 37px;
        height: 42px;
        background: url(../../static/image/b410a9cdae344c71adc899b2c90a3867_mergeImage.png) no-repeat;
        background-size: 37px 42px;
        .flex_center()
      }
      .bd3 {
        width: 40px;
        height: 42px;
        background: url(../../static/image/c7875aa34eda4eb59c2b23ae00c28b3b_mergeImage.png) no-repeat;
        background-size: 40px 42px;
        .flex_center()
      }
      .bd4 {
        width: 40px;
        height: 42px;
        background: url(../../static/image/c4923b935ced423dbdda22aacb222473_mergeImage.png) no-repeat;
        background-size: 40px 42px;
        .flex_center()
      }
      .bd5 {
        width: 40px;
        height: 42px;
        background: url(../../static/image/b73b86ed5d6c4f5e8006c11cd6c770ed_mergeImage.png) no-repeat;
        background-size: 40px 42px;
        .flex_center()
      }
      .flex_center{
          display: flex;
          align-items: center;
          justify-content: center;
          span{
              padding-left: 150px;
              line-height: 30px;
          }
      }
      
      .txt1 {
        width: 125px;
        height: 31px;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 32px;
        text-align: left;
        white-space: nowrap;
        display: block;
        margin-left: 73px;
      }
      
      .icon1 {
        width: 12px;
        height: 22px;
        margin: 7px 0 0 449px;
      }
      
      .mod4 {
        width: 90%;
        height: 44px;
        // margin: 76px 0 0 47px;
        margin: 20px auto 47px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      
      
      .txt2 {
        width: 125px;
        height: 31px;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 32px;
        text-align: left;
        white-space: nowrap;
        display: block;
        margin-left: 73px;
      }
      
      .label1 {
        width: 12px;
        height: 22px;
      }
      
      .mod5 {
        width: 659px;
        height: 42px;
        margin: 75px 0 0 43px;
      }
      
      
      .txt3 {
        width: 126px;
        height: 30px;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 32px;
        font-family: SourceHanSansCN-Medium;
        text-align: left;
        white-space: nowrap;
        line-height: 32px;
        display: block;
        margin: 8px 0 0 32px;
      }
      
      .label2 {
        width: 12px;
        height: 22px;
        margin: 12px 0 0 447px;
      }
      
      .mod6 {
        width: 658px;
        height: 42px;
        margin: 81px 0 0 44px;
      }
      
      
      .word9 {
        width: 126px;
        height: 31px;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 32px;
        font-family: SourceHanSansCN-Medium;
        text-align: left;
        white-space: nowrap;
        line-height: 32px;
        display: block;
        margin: 5px 0 0 31px;
      }
      
      .icon2 {
        width: 12px;
        height: 22px;
        margin: 10px 0 0 449px;
      }
      
      .mod8 {
        width: 659px;
        height: 38px;
        margin: 81px 0 0 43px;
      }
      
      .word10 {
        width: 126px;
        height: 30px;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 32px;
        font-family: SourceHanSansCN-Medium;
        text-align: left;
        white-space: nowrap;
        line-height: 32px;
        display: block;
        margin: 4px 0 0 31px;
      }
      
      .icon3 {
        width: 12px;
        height: 22px;
        margin: 8px 0 0 448px;
    }
      .flex-col {
        display: flex;
        flex-direction: column;
      }
      .flex-row {
        display: flex;
        flex-direction: row;
      }
      .justify-start {
        display: flex;
        justify-content: flex-start;
      }
      .justify-center {
        display: flex;
        justify-content: center;
      }
      
      .justify-end {
        display: flex;
        justify-content: flex-end;
      }
      .justify-evenly {
        display: flex;
        justify-content: space-evenly;
      }
      .justify-around {
        display: flex;
        justify-content: space-around;
      }
      .justify-between {
        display: flex;
        justify-content: space-between;
      }
      .align-start {
        display: flex;
        align-items: flex-start;
      }
      .align-center {
        display: flex;
        align-items: center;
      }
      .align-end {
        display: flex;
        align-items: flex-end;
      }
      .text-center{
          text-align: center;
      }
      
}
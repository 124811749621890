.voteTopic{
    width: 100%;
    height: auto;
    padding: 40px 30px 30px;
    box-sizing: border-box;
    .title_icon{
        height: 60px;
        background: #f1fcfb;
        color: #33cec1;
        border-radius: 16px;
        // display: flex;
        align-items: center;
        margin-bottom: 36px;
        padding: 0 20px 0px 0;
        display: inline-block;
        line-height: 60px;
        img{
            width: 37px;
            height: 36px;
            margin-right: 15px;
            margin-left: 16px;
            float: left;
            margin-top: 10px;
        }
    }
    .title{
        font-size: 40px;
        font-weight: bold;
        color: #000000;
        line-height: 58px;
        margin: 0px 0 30px;
    }
    .list{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .item_box{
            width: 100%;
            // height: 82px;
            border-radius: 10px;
            overflow: hidden;
            position: relative;
            margin-bottom: 20px;
            &.active{
                .line_bg div{
                    background-color: #dff7f5;
                }
                .item{
                    border: 1px solid #87e2da;
                    .num{
                        color: #04c2b2;
                    }
                }
            }
            .line_bg{
                // width: 30%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
                box-sizing: border-box;
                div{
                    width: 100%;
                    height: 100%;
                    background-color: #f1f1f1;
                }
            }
            .item{
                position: relative;
                z-index: 2;
                border-radius: 10px;
                border: 1px solid #D2D2D2;
                overflow: hidden;
                width: 100%;
                line-height: 40px;
                padding: 30px 34px;
                box-sizing: border-box;
                font-size: 30px;
                color: #333333;
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: space-between;
                .text{
                    width: 90%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                }
            }
        }
        
    }
    .comment{
        width: 96%;
        height: auto;
        background: #FFFFFF;
        border-radius: 10px;
        padding: 24px;
        box-sizing: border-box;
        box-shadow: 0px 2px 20px 0px rgba(26, 43, 89, 0.14);
        position: relative;
        margin: 20px auto 30px;
        .toupiao_commen_icon{
            width: 30px;
            height: 40px;
            position: absolute;
            top: -24px;
            left: 40px;
        }
        .author{
            width: 100%;
            font-size: 24px !important;
            font-weight: 400;
            color: #7F7F7F !important;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            img{
                width: 44px;
                height: 44px;
                border-radius: 50%;
                margin-right: 8px;
            }
            span{
                color: #BDBDBD;
                margin-left: 37px;
            }
            .name{
                max-width: 60%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                color: #7F7F7F;
                margin-left: 0;
            }
        }
        .comment_title{
            margin-top: 24px;
            margin-bottom: 17px;
            width: 100%;
            font-size: 30px;
            color: #000000;
            line-height: 44px;
            font-weight: 400;
        }
        .comment_content{
            width: 100%;
            font-size: 26px;
            color: #333333;
            line-height: 40px;
        }
    }
    .footer{
        width: 100%;
        box-sizing: border-box;
        padding-right: 10px;
        text-align: right;
        font-size: 24px;
        color: #BDBDBD;
        span{
            margin-left: 30px;
        }
    }
    .author_list{
        margin-top: 29px;
        width: 100%;
        padding: 0 30px;
        box-sizing: border-box;
        border-top: 1px solid #E5E5E5;
        padding-top: 26px;
        .author_list_item{
            width: 100%;
            height: 55px;
            display: flex;
            align-items: center;
            font-size: 24px;
            color: #000000;
            box-sizing: border-box;
            padding-left: 6px;
            img{
                width: 44px;
                border-radius: 50%;
                margin-left: 19px;
                margin-right: 9px;
            }
            span{
                color: #7F7F7F;
            }
            .hot{
                margin-left: 23px;
                margin-right: 10px;
            }
            p{
                display: inline-block;
                background-color: #C40B44;
                border-radius: 6px;
                color: #ffffff;
                text-align: center;
                padding: 20px 10px;
                line-height: 0;
                margin-left: 11px;
                font-size: 20px;
            }
        }
    }
    .topic_text{
        margin: 20px 30px 0;
        // width: 100%;
        // height: 190px;
        background: #F5F5F5;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 30px 27px 0;
        font-size: 28px;
        line-height: 44px;
        color: #333333;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        border-bottom: 15px solid #F5F5F5;
        span{
            color: #005D9D;
        }
    }
}